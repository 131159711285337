import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LoadingPropertyItem from "../components/LoadingPropertyItem";
import ListingCard from "../components/ListingCard";
import _ from "lodash";
import {
  Business,
  Close,
  KeyboardArrowRight,
  Place,
} from "@mui/icons-material";
import Deal0 from "../assets/schedule.png";
import Deal1 from "../assets/adsec2.png";
import Deal2 from "../assets/explore.png";
import { cities, hoods } from "../constants";
import Neighborhood from "../components/Neighborhood";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { grey } from "@mui/material/colors";
import {
  filterActiveListings,
  filterListingsWithoutPhotos,
  filterListingsWithPhotos,
} from "../actions";
import SEO from "../components/SEO";
import { listingURL } from "../api/apis";
import { ProfessionalsHome } from "../components/newProfessionals/ProfessionalsHome";

const SearchBox = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${grey[300]}`,
  display: "block",
}));

const Homepage = () => {
  const filterInput = useSelector((state) => state.propertyFilter);
  const currentUser = useSelector((state) => state.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);
  const [searchFocus, setSearchFocus] = useState(false);
  const [searchResult, setSearchResult] = useState();
  const [recentSearch, setRecentSearch] = useState([]);
  const [resType, setResType] = useState();
  const [value, setValue] = useState("");
  const [timer, setTimer] = useState(null);
  // const [places, setPlaces] = useState(null)

  const fetchData = async () => {
    try {
      const { data: res } = await listingURL.get(
        `residential/merge_listings/?limit=10`
      );
      const results = filterListingsWithPhotos(res?.data);
      const activeListings = filterActiveListings(results);
      const randomSample = _.sampleSize(activeListings, 8);
      setData(randomSample);
      setLoading(false);
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const searchHood = (name) => {
    const search = name.toLowerCase();
    navigate(`/homes-for-sale/${search}`);
  };

  const searchFilter = async (e) => {
    e.stopPropagation();
    if (filterInput.search === "") {
      return;
    }
    // if (searchResult?.length > 1) {
    //     setOpen(true)
    //     setSearchFocus(false)
    // }
    else {
      if (resType === "address") {
        navigate(
          `/listing/${searchResult[0]?.mlsId}/details/${
            searchResult[0]?.listing === "mls" ? "mls" : "crmls"
          }`
        );
      }
      if (resType === "city") {
        const places = JSON.parse(localStorage.getItem("places"));
        navigate(
          `/homes-for-sale/${_.startCase(
            searchResult[0]?.address?.city || places[0]?.name
          )?.toLowerCase()}`
        );
      }
    }
    // searchHood(value)
  };

  const getSearchLocation = (val) => {
    //eslint-disable-next-line no-undef
    new google.maps.places.PlacesService(
      document.getElementById("map")
    ).findPlaceFromQuery(
      { query: val, fields: ["name", "formatted_address"] },
      (resPlaces) => {
        localStorage.setItem("places", JSON.stringify(resPlaces));
      }
    );
  };

  const onSearchHandler = (e) => {
    let query = [];

    if (e.target.value !== "") {
      setValue(e.target.value);

      // clear timer
      clearTimeout(timer);

      // new timer
      const newTimer = setTimeout(async () => {
        setSearchLoad(true);
        // localStorage.removeItem('places')
        if (e.target.value.includes(",")) {
          const q = e.target.value.split(",").join(" ");
          query = `q=${q}`;
        } else {
          const isIncluded = cities.some(
            (el) => el === e.target.value?.toLowerCase()
          );
          query = isIncluded
            ? `q=${e.target.value?.replace(/\s+/g, "")}`
            : `q=${e.target.value}`;
        }
        const { data: res } = await listingURL.get(
          `residential/merge_listings?${query}&limit=40`
        );
        const activeListings = filterActiveListings(res?.data);
        const results = filterListingsWithoutPhotos(activeListings);

        results?.length === 0
          ? getSearchLocation(e.target?.value)
          : localStorage.removeItem("places");
        setSearchLoad(false);
        setSearchResult(results);
        setRecentSearch(res?.resent_search?.reverse());
        setResType(res?.name);
        setSearchFocus(true);
      }, 200);

      // save timerID
      setTimer(newTimer);
    } else {
      setValue("");
      setSearchResult();
    }
  };

  // const onSearchHandler = useDebouncedSearch(
  //   value,
  //   setValue,
  //   setSearchLoad,
  //   setSearchResult,
  //   setRecentSearch,
  //   setResType,
  //   setSearchFocus,
  //   listingURL,
  //   cities,
  //   filterActiveListings,
  //   filterListingsWithoutPhotos,
  //   getSearchLocation
  // );

  const clickAway = (e) => {
    window.addEventListener("click", function (e) {
      if (document.getElementById("searchbox").contains(e.target)) {
        setSearchFocus(true);
      } else {
        setSearchFocus(false);
      }
    });
  };

  useEffect(() => {
    if (searchFocus && !value) {
      // recentSearch?.length === 0 &&
      const event = {
        target: {
          value: " ",
        },
      };
      onSearchHandler(event);
      setValue("");
    }
  }, [searchFocus, value]); // recentSearch,

  return (
    <div>
      <SEO>
        <title>BPO Homes - Find Your Dream Home Today</title>
        <meta
          name="og:title"
          content="BPO Homes - Find Your Dream Home Today"
        />
        <meta
          name="description"
          content="BPO Homes offers exclusive property listings across various neighborhoods. Explore our listings, connect with our agents, and find your dream home today. Join our Partner Agent Program for more benefits."
        />
        <meta
          name="og:description"
          content="BPO Homes offers exclusive property listings across various neighborhoods. Explore our listings, connect with our agents, and find your dream home today. Join our Partner Agent Program for more benefits."
        />
      </SEO>
      {/* <Banner
        load={searchLoad}
        results={searchResult}
        type={resType}
        searchFilter={searchFilter}
        onChange={onSearchHandler}
        clickAway={clickAway}
        value={value}
        focus={() => setSearchFocus(true)}
        searchFocus={searchFocus}
        searchTerm={value}
        recent={recentSearch}
      /> */}
      <ProfessionalsHome
        load={searchLoad}
        results={searchResult}
        type={resType}
        searchFilter={searchFilter}
        onChange={onSearchHandler}
        clickAway={clickAway}
        focus={() => setSearchFocus(true)}
        searchFocus={searchFocus}
        searchTerm={value}
        recent={recentSearch}
      >
        <Grid container spacing={3}>
          {loading
            ? Array(4)
                .fill()
                .map((_, ld) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={ld}>
                      <LoadingPropertyItem />
                    </Grid>
                  );
                })
            : data?.length > 0 &&
              data?.length &&
              data?.slice(0, 4)?.map((item) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={item.listingId}>
                    <ListingCard
                      listDate={item.listDate}
                      mlsId={item.mlsId}
                      status={"For Sale"}
                      address={_.startCase(
                        item.address?.full +
                          " " +
                          item.address?.city +
                          ", " +
                          item.address?.state +
                          " " +
                          item.address?.postalCode
                      )}
                      price={item?.listPrice?.toLocaleString()}
                      image={
                        item?.photos[1] ? item?.photos[1] : item?.photos[0]
                      }
                      bed={item.property?.bedrooms}
                      bath={item.property?.bathsFull}
                      area={item.property?.area}
                      vendor={item?.listing === "mls" ? "mls" : "crmls"}
                      active={item?.mls?.status}
                    />
                  </Grid>
                );
              })}
        </Grid>
      </ProfessionalsHome>
      <Box id="map" />
      <Box py={"4rem"}>
        <Container maxWidth="xl">
          {/* <Typography
            variant="h4"
            component={"h2"}
            sx={{
              fontWeight: 500,
              mb: ".5rem",
              fontSize: {
                xs: "1.6rem",
                sm: "1.8rem",
                md: "2rem",
                lg: "2.2rem",
              },
            }}
          >
            {" "}
            Explore Listings
          </Typography>
          <Typography variant="body1" mb={"2rem"}>
            Be the first to browse exclusive listings before they hit the
            market.
          </Typography> */}

          <Grid container spacing={3} my={8}>
            <Grid item sm={12} md={6} lg={6}>
              <ImageList
                variant="quilted"
                cols={2}
                gap={10}
                rowHeight={219}
                sx={{ margin: "0 auto" }}
              >
                <ImageListItem cols={1} rows={2}>
                  <img
                    loading="lazy"
                    src={`${Deal0}?fit=crop&auto=format`}
                    alt={"right deals"}
                  />
                </ImageListItem>
                <ImageListItem cols={1} rows={1}>
                  <img
                    loading="lazy"
                    src={`${Deal1}?fit=crop&auto=format`}
                    alt={"right_deals"}
                  />
                </ImageListItem>
                <ImageListItem cols={1} rows={1}>
                  <img
                    loading="lazy"
                    src={`${Deal2}?fit=crop&auto=format`}
                    alt={"right_deals_"}
                  />
                </ImageListItem>
              </ImageList>
            </Grid>
            <Grid item sm={12} md={6} lg={6}>
              <Box
                sx={{ padding: "4rem", height: "20rem" }}
                bgcolor="secondary.main"
                color="#fff"
              >
                <Typography
                  variant="h4"
                  component={"h2"}
                  gutterBottom
                  sx={{
                    fontWeight: 500,
                    fontSize: {
                      xs: "1.6rem",
                      sm: "1.8rem",
                      md: "2rem",
                      lg: "2.2rem",
                    },
                  }}
                >
                  Join our Partner Agent Program
                </Typography>
                <Typography mb={3}>
                  Join our Partner Agent Program, receive referrals from BPO
                  Homes as a Buyer & Listing Agent. Enroll Now for FREE!
                </Typography>
                <Button
                  size="large"
                  onClick={() => {
                    currentUser
                      ? navigate("/join")
                      : dispatch({ type: "AUTH_LOGIN" });
                  }}
                  endIcon={<KeyboardArrowRight />}
                  variant="contained"
                  disableElevation
                  color="paper"
                  sx={{
                    textTransform: "none",
                    fontSize: "1rem",
                    color: "#1378A5",
                  }}
                >
                  Signup Today
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Box>
            <Typography
              variant="h4"
              component={"h2"}
              sx={{
                fontWeight: 500,
                mb: ".5rem",
                fontSize: {
                  xs: "1.6rem",
                  sm: "1.8rem",
                  md: "2rem",
                  lg: "2.2rem",
                },
              }}
            >
              {" "}
              Find Homes For Sale in your preferred Neighborhood.
            </Typography>
            <Typography variant="body1" mb={"2rem"}>
              The neighborhoods with homes for sale, and the agents who know
              them best.
            </Typography>
            <Grid container spacing={2}>
              {_.sampleSize(hoods, 8).map((hd, index) => {
                return (
                  <Grid item key={index} xs={6} sm={6} md={4} lg={3}>
                    <Neighborhood
                      onClick={() => searchHood(hd.city)}
                      name={hd.city}
                      image={hd.img}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Container>
      </Box>

      {/* Check for search results */}
      <Dialog open={open} maxWidth="sm" fullWidth>
        <DialogContent sx={{ padding: "2rem" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Do You Mean</Typography>
            <IconButton onClick={() => setOpen(false)}>
              <Close fontSize="small" />
            </IconButton>
          </Box>
          <Divider sx={{ my: "10px" }} />
          <Box
            maxHeight={"32rem"}
            sx={{ overflowY: "scroll", minHeight: "fit-content" }}
          >
            {resType === "city" && (
              <SearchBox>
                <Typography
                  textAlign={"left"}
                  sx={{
                    ml: "1rem",
                    mt: "1rem",
                    fontWeight: 500,
                    color: grey[500],
                  }}
                >
                  Places
                </Typography>
                {searchResult?.slice(0, 5).map((res) => {
                  return (
                    <ListItem
                      component="a"
                      onClick={async (e) => {
                        e.stopPropagation();
                        await listingURL.post(`search-upload/`, {
                          search: res?.address?.city || res?.name,
                        });
                        navigate(
                          `/homes-for-sale/${
                            _.startCase(res?.address?.city)?.toLowerCase() ||
                            res?.name
                          }`
                        );
                      }}
                      button
                      key={res.mlsId}
                    >
                      <ListItemIcon sx={{ minWidth: "25px" }}>
                        {<Place sx={{ fontSize: "1rem" }} />}
                      </ListItemIcon>
                      <ListItemText>
                        <Typography>
                          {res?.address
                            ? _.startCase(res?.address?.city)
                            : res?.name}
                        </Typography>
                        <Typography variant="body2" color={"GrayText"}>
                          {res?.address
                            ? _.startCase(
                                res.address?.crossStreet?.toLowerCase()
                              ) +
                              " " +
                              _.startCase(res.address?.city) +
                              ", " +
                              _.startCase(res.address?.state) +
                              ", USA"
                            : res?.formatted_address}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </SearchBox>
            )}
            {resType === "address" && (
              <SearchBox>
                <Typography
                  textAlign={"left"}
                  sx={{
                    ml: "1rem",
                    mt: "1rem",
                    fontWeight: 500,
                    color: grey[500],
                  }}
                >
                  Addresses
                </Typography>
                {searchResult?.slice(0, 5).map((res) => {
                  return (
                    <ListItem
                      onClick={async (e) => {
                        e.stopPropagation();
                        await listingURL.post(`search-upload/`, {
                          search: res?.address?.full || res?.name,
                        });
                        navigate(
                          `/listing/${res?.mlsId}/details/${
                            res?.listing === "mls" ? "mls" : "crmls"
                          }`
                        );
                      }}
                      button
                      key={res?.mlsId}
                      sx={{
                        padding: ".5rem 1.5rem",
                        alignItems: "flex-start",
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: "25px", pt: ".6rem" }}>
                        {
                          <Business
                            color="secondary"
                            sx={{ fontSize: "1rem" }}
                          />
                        }
                      </ListItemIcon>
                      <ListItemText>
                        <Typography>
                          {res?.address
                            ? _.startCase(res?.address?.full)
                            : res?.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ fontSize: ".9rem" }}
                          color={"GrayText"}
                        >
                          {res?.address
                            ? _.startCase(res?.address?.city) +
                              ", " +
                              _.startCase(res?.address?.state) +
                              " " +
                              res?.address?.postalCode
                            : res?.name}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </SearchBox>
            )}

            <Typography
              mt={2}
              textAlign={"center"}
              sx={{ fontSize: ".8rem" }}
              variant="body2"
            >
              Don't see what you're looking for? <br />
              Your search might be outside our service areas.
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Homepage;
