import { Business, Place, Restore, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import {
  Box,
  Button,
  Chip,
  Divider,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { grey } from "@mui/material/colors";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import base, { listingURL } from "../../../api/apis";

const InputField = styled(TextField)(({ theme }) => ({
  margin: ".7rem 0",

  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    background: "#fff",
    paddingRight: "12px",
  },
}));

const SearchBox = styled(Box)(({ theme }) => ({
  borderBottom: `0px solid ${grey[300]}`,
  display: "block",
}));

const StyledWrapper = styled(Box)(() => ({
  marginTop: "-5px",
  backgroundColor: "#fff",
  position: "absolute",
  padding: "8px 0",
  zIndex: 9,
  borderRadius: "10px",
  boxShadow: "0 2px 20px rgba(0,0,0,15%)",
}));

export const SearchComp = ({
  load,
  clickAway,
  onChange,
  focus,
  searchFilter,
  results,
  searchFocus,
  searchTerm,
  type,
  recent,
}) => {
  const navigate = useNavigate();

  const allResults = useMemo(() => {
    return results?.length !== 0
      ? results
      : JSON.parse(localStorage.getItem("places"));
  }, [results]);

  const hasMatchingResults = useMemo(() => {
    if (!searchTerm || !allResults || allResults.length === 0) {
      return false;
    }

    const lowerSearchTerm = searchTerm.toLowerCase();

    return allResults.some((result) => {
      // Check if any property of the object contains the search term
      for (const key in result) {
        if (
          typeof result[key] === "string" &&
          result[key].toLowerCase().includes(lowerSearchTerm)
        ) {
          return true;
        }
      }
      return false;
    });
  }, [searchTerm, allResults]);

  // get users location if they accept permission
  function getGeoLocation() {
    const geoAlertText =
      "Geolocation is not supported by your browser or it is disabled. Please check your settings. ";

    async function success(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      try {
        const { data } = await base.post("/bpo_listings/get-location/", {
          latitude,
          longitude,
        });
        await listingURL.post(`search-upload/`, {
          search: data?.city,
        });
        navigate(`/homes-for-sale/${_.startCase(data?.city)?.toLowerCase()}`);
      } catch (error) {}
    }
    function error(message) {
      alert(geoAlertText);
    }
    if (!navigator?.geolocation) {
      alert(geoAlertText);
    } else {
      navigator.geolocation.getCurrentPosition(success, error);
    }
  }

  const RecentSearch = ({ list = [] }) => (
    <SearchBox pb={1}>
      <Typography
        variant="body2"
        textAlign={"left"}
        sx={{
          ml: "1rem",
          mt: "1rem",
          mb: " .5rem",
          fontWeight: 500,
          color: grey[600],
          fontSize: ".8rem",
        }}
      >
        RECENT SEARCH
      </Typography>
      {list?.slice(0, 2)?.map((res, index) => {
        return (
          <ListItem
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/homes-for-sale/${res?.search}`);
            }}
            button
            key={index}
            sx={{
              padding: ".5rem 1rem",
              alignItems: "flex-start",
            }}
          >
            <ListItemIcon sx={{ minWidth: "25px", pt: ".4rem" }}>
              {<Restore color="secondary" sx={{ fontSize: "1.1rem" }} />}
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body2">
                {_.startCase(res?.search)}
              </Typography>
              {/* <Typography variant='body2' sx={{fontSize: '.9rem'}} color={'GrayText'}>{_.startCase(res?.address?.city) + ', ' + _.startCase(res?.address?.state) + ' ' + res?.address?.postalCode}</Typography> */}
            </ListItemText>
          </ListItem>
        );
      })}
    </SearchBox>
  );

  return (
    <Box width={"95%"} margin={"0 auto"} position={"relative"}>
      <form onSubmit={searchFilter} autoComplete="off">
        <InputField
          type={"search"}
          id="searchbox"
          variant="outlined"
          sx={{ zIndex: 10 }}
          placeholder="Enter an address, city, neighborhood or zip code"
          onBlur={clickAway}
          onChange={onChange}
          onFocus={focus}
          value={searchTerm}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <LoadingButton
                  variant="contained"
                  color="secondary"
                  onClick={searchFilter}
                  disableElevation
                  loading={load}
                  sx={{
                    padding: ".7rem",
                    borderRadius: "10px",
                    marginRight: "-8px",
                    "&:hover": {
                      bgcolor: "primary.main",
                    },
                  }}
                >
                  <Search color="paper" />
                </LoadingButton>
              </InputAdornment>
            ),
          }}
        />
      </form>

      {searchTerm ? (
        <>
          {allResults?.length > 0 && searchFocus ? (
            <StyledWrapper
              sx={{ width: { xs: "76%", sm: "86%", md: "86%", lg: "100%" } }}
            >
              <Box
                sx={{
                  maxHeight: "50vh",
                  overflowY: "scroll",
                }}
              >
                {hasMatchingResults && (
                  <>
                    {recent && recent?.length > 0 && (
                      <>
                        {/* Recent Search */}
                        <RecentSearch list={recent} />
                        <Divider light />
                      </>
                    )}
                  </>
                )}
                {type === "city" ? (
                  <SearchBox>
                    <Typography
                      variant="body2"
                      textAlign={"left"}
                      sx={{
                        ml: "1rem",
                        mt: "1rem",
                        fontWeight: 500,
                        color: grey[600],
                        fontSize: ".8rem",
                      }}
                    >
                      PLACES
                    </Typography>
                    {allResults?.slice(0, 4)?.map((res, index) => {
                      return (
                        <ListItem
                          onClick={async (e) => {
                            e.stopPropagation();
                            await listingURL.post(`search-upload/`, {
                              search: res?.address?.city || res?.name,
                            });
                            navigate(
                              `/homes-for-sale/${_.startCase(
                                res?.address?.city || res?.name
                              )?.toLowerCase()}`
                            );
                          }}
                          button
                          key={index}
                          sx={{
                            padding: ".3rem 1.5rem",
                            alignItems: "flex-start",
                          }}
                        >
                          <ListItemIcon sx={{ minWidth: "25px", pt: ".6rem" }}>
                            {
                              <Place
                                color="secondary"
                                sx={{ fontSize: "1rem" }}
                              />
                            }
                          </ListItemIcon>
                          <ListItemText>
                            {index === 0 ? (
                              <>
                                <Typography>
                                  {res?.address
                                    ? _.startCase(res?.address?.city) +
                                      ", " +
                                      _.startCase(res?.address?.state)
                                    : res?.name}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{ fontSize: "0.85rem" }}
                                  color={"GrayText"}
                                >
                                  {res?.address
                                    ? _.startCase(res?.address?.city) +
                                      ", " +
                                      _.startCase(res?.address?.state) +
                                      ", USA"
                                    : res?.formatted_address}
                                </Typography>
                              </>
                            ) : (
                              <>
                                <Typography>
                                  {res?.address
                                    ? _.startCase(res?.address?.city)
                                    : res?.name}
                                </Typography>
                                <Typography variant="body2" color={"GrayText"}>
                                  {res?.address
                                    ? _.startCase(
                                        res.address?.crossStreet?.toLowerCase()
                                      ) +
                                      " " +
                                      _.startCase(res.address?.city) +
                                      ", " +
                                      _.startCase(res.address?.state) +
                                      ", USA"
                                    : res?.formatted_address}
                                </Typography>
                              </>
                            )}
                          </ListItemText>
                        </ListItem>
                      );
                    })}
                  </SearchBox>
                ) : (
                  <SearchBox>
                    <Typography
                      variant="body2"
                      textAlign={"left"}
                      sx={{
                        ml: "1rem",
                        mt: "1rem",
                        fontWeight: 500,
                        color: grey[600],
                        fontSize: ".8rem",
                      }}
                    >
                      ADDRESSES
                    </Typography>
                    {allResults?.slice(0, 4)?.map((res, index) => {
                      return (
                        <ListItem
                          onClick={async (e) => {
                            e.stopPropagation();
                            await listingURL.post(`search-upload/`, {
                              search: res?.address?.full || res?.name,
                            });
                            navigate(
                              `/listing/${res?.mlsId}/details/${
                                res?.listing === "mls" ? "mls" : "crmls"
                              }`
                            );
                          }}
                          button
                          key={index}
                          sx={{
                            padding: ".5rem 1.5rem",
                            alignItems: "flex-start",
                          }}
                        >
                          <ListItemIcon sx={{ minWidth: "25px", pt: ".6rem" }}>
                            {
                              <Business
                                color="secondary"
                                sx={{ fontSize: "1rem" }}
                              />
                            }
                          </ListItemIcon>
                          <ListItemText>
                            <Typography>
                              {res?.address
                                ? _.startCase(res?.address?.full)
                                : res?.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ fontSize: ".9rem" }}
                              color={"GrayText"}
                            >
                              {res?.address
                                ? _.startCase(res?.address?.city) +
                                  ", " +
                                  _.startCase(res?.address?.state) +
                                  " " +
                                  res?.address?.postalCode
                                : res?.formatted_address}
                            </Typography>
                          </ListItemText>
                          {res?.mls?.status && (
                            <Chip
                              size="small"
                              color={
                                res?.mls?.status?.toLowerCase() === "sold" ||
                                res?.mls?.status?.toLowerCase() === "closed"
                                  ? "error"
                                  : "default"
                              }
                              sx={{ ml: "auto" }}
                              label={res?.mls?.status}
                            />
                          )}
                        </ListItem>
                      );
                    })}
                  </SearchBox>
                )}
              </Box>
            </StyledWrapper>
          ) : (!allResults || allResults?.length === 0) &&
            searchFocus &&
            !load ? (
            <StyledWrapper
              sx={{ width: { xs: "76%", sm: "86%", md: "86%", lg: "100%" } }}
            >
              <SearchBox sx={{ padding: "1rem" }}>
                <Typography textAlign={"center"}>No listing found</Typography>
              </SearchBox>
            </StyledWrapper>
          ) : null}
        </>
      ) : (
        <>
          {/* Location Suggestion */}
          {searchFocus && (
            <Box
              bgcolor={"white"}
              sx={{
                width: "100%",
                borderRadius: "10px",
                marginTop: "-5px",
                position: "absolute",
                paddingY: ".5rem",
              }}
            >
              <Button
                onClick={getGeoLocation}
                fullWidth
                sx={{
                  textTransform: "none",
                  textAlign: "start",
                  justifyContent: "start",
                  height: "3rem",
                  paddingLeft: "0.9rem",
                  "&:hover": {
                    bgcolor: grey[100],
                  },
                }}
                startIcon={<NearMeOutlinedIcon />}
              >
                Search your Location
              </Button>

              {recent && recent?.length > 0 && (
                <>
                  {/* Recent Search */}
                  <Divider light />
                  <RecentSearch list={recent} />
                </>
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
