import {
  Favorite,
  FavoriteBorder,
  KingBed,
  Shower,
  SquareFoot,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Chip,
  IconButton,
  Typography,
  styled,
  Tooltip,
  CircularProgress,
  Stack,
  Divider,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { addToFavorite, removeFavorite } from "../actions";
import { getOpenHouseTime } from "../utils/functions";
import _ from "lodash";
import { FavoritePopUp } from "./favorites/Favorite";
import { grey } from "@mui/material/colors";

const StyledCard = styled(Card)(({ theme, border, inView }) => ({
  boxShadow: "0 1px 10px rgba(0,0,0, 12%)",
  borderRadius: "10px",
  border: border ? `1px solid ${theme.secondaryColor}` : `1px solid #fff`,
  transition: "all .2s ease",
  "&:hover": {
    border: `1px solid ${theme.secondaryColor}`,
    cursor: "pointer",
    "& #lb": {
      background: theme.palette.primary.main,
      color: "#000",
      fontWeight: 500,
    },
  },
}));

const ImageBox = styled(Box)(({ theme, image }) => ({
  height: "14rem",
  backgroundImage: `url(${image})`,
  backgroundColor: grey[200],
  backgroundPosition: "center",
  backgroundSize: "cover",
  padding: "1rem",
}));

const ListingCard = (props) => {
  const {
    id,
    inView,
    price,
    image,
    bed,
    bath,
    area,
    address,
    mlsId,
    border,
    addToFavorite,
    removeFavorite,
    listDate,
    vendor,
    status,
    startTime,
    endTime,
    active,
    office,
  } = props;
  const currentUser = useSelector((state) => state.currentUser);
  const { data } = useSelector((state) => state.categories);
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const [isFav, setIsFav] = useState(false);
  const [open, setOpen] = useState(false);
  const [primaryFavorite, setPrimaryFavorite] = useState({
    name: "",
    id: "",
    is_primary: false,
  });
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    // const { data } = await base.get("favorite/category/");
    setCategories(data);

    const primary = data.filter((p) => p.is_primary);
    setPrimaryFavorite(primary?.[0]);
  }, [data]);

  useEffect(() => {
    if (currentUser) {
      //console.log(currentUser)
      const res =
        currentUser?.favs.filter((el) => {
          return el.property_id === mlsId;
        }).length > 0;
      setIsFav(res);
    } else {
      setIsFav(false);
    }
  }, [currentUser, mlsId]);

  const addToFav = async (e) => {
    e.stopPropagation();
    if (currentUser) {
      if (currentUser.favs.filter((el) => el.property_id === mlsId)[0]) {
        //remove from favs
        setLoad(true);
        const status = await removeFavorite(mlsId);
        if (status === 204) {
          setLoad(false);

          dispatch({
            type: "SUCCESS",
            payload: "Property removed from favorites",
          });
        } else {
          setLoad(false);
          dispatch({
            type: "SUCCESS",
            payload: "Sorry, could not add to favorite",
          });
        }
      } else {
        //add to favs
        setLoad(true);

        const data = {
          user_id: currentUser.id,
          property_id: mlsId,
          address: address,
          price: Number(price.split(",").join("")),
          image: image,
          bedrooms: bed,
          baths: bath,
          area: area,
          list_date: new Date(listDate),
        };

        const status = await addToFavorite(data);
        dispatch({
          type: "FAVORITE",
          payload: data,
        });
        if (status === 200) {
          setLoad(false);
          setOpen(true);
          // dispatch({ type: "SUCCESS", payload: "Property added to favorites" });
        } else {
          setLoad(false);
          dispatch({
            type: "ERROR",
            payload: "Sorry, could not add to favorite",
          });
        }
      }
    } else {
      dispatch({ type: "AUTH_LOGIN" });
    }
  };

  const getDaysOnMarket = (listDate) => {
    if (listDate) {
      const diff =
        new Date(Date.now()).getTime() - new Date(listDate).getTime();
      const dy = Math.round(diff / (1000 * 3600 * 24));
      if (dy <= 30) {
        return "New on BPO Homes";
      } else {
        return dy;
      }
    }
  };

  return (
    <>
      <FavoritePopUp
        setCategories={setCategories}
        primaryFavorite={primaryFavorite}
        categories={categories}
        open={open}
        setOpen={setOpen}
      />
      <StyledCard
        id={id}
        style={{ border: inView ? "4px solid #1378A5" : "none" }}
        onClick={() => {
          // localStorage.setItem("priorRoute", window.location.pathname);
          status === "openhouse"
            ? window.open(`/open-houses/${mlsId}/details/${vendor}`, "_blank")
            : status === "soldhomes"
            ? window.open(`/sold-homes/${mlsId}/details/${vendor}`, "_blank")
            : status === "rental"
            ? window.open(`/rental/${mlsId}/details/${vendor}`, "_blank")
            : window.open(`/listing/${mlsId}/details/${vendor}`, "_blank");
        }}
        border={border}
        title={address}
      >
        <ImageBox image={image}>
          {status === "openhouse" ? (
            <Chip
              component={"span"}
              sx={{
                marginLeft: "5px",
                bgcolor: "primary.main",
                boxShadow: "0 1px 5px rgba(0,0,0, 20%)",
                transition: "all .2s ease",
              }}
              size="small"
              label={
                <Typography sx={{ fontSize: ".7rem", fontWeight: 400 }}>
                  {getOpenHouseTime(startTime, endTime)}
                </Typography>
              }
            />
          ) : (
            <>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                {getDaysOnMarket(listDate) >= 30 ||
                active === "Sold" ||
                active === "Closed" ? null : (
                  <Chip
                    component={"span"}
                    sx={{
                      marginLeft: "5px",
                      bgcolor: "primary.main",
                      boxShadow: "0 1px 5px rgba(0,0,0, 20%)",
                      transition: "all .2s ease",
                    }}
                    size="small"
                    label={getDaysOnMarket(listDate)}
                  />
                )}
                {active === "Sold" || active === "Closed" ? (
                  <Chip
                    component={"span"}
                    size="small"
                    sx={{
                      color: "#fff",
                      marginLeft: "auto",
                      bgcolor: "secondary.main",
                      boxShadow: "0 1px 5px rgba(0,0,0, 20%)",
                      transition: "all .2s ease",
                    }}
                    label={
                      <Typography
                        sx={{ fontSize: ".8rem", fontWeight: 500 }}
                      >{`${
                        "SOLD " +
                        new Date(listDate)
                          .toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          })
                          ?.toUpperCase()
                      }`}</Typography>
                    }
                  />
                ) : (
                  <Chip
                    component={"span"}
                    size="small"
                    sx={{
                      marginLeft: "auto",
                      bgcolor: "#fff",
                      boxShadow: "0 1px 5px rgba(0,0,0, 20%)",
                      transition: "all .2s ease",
                    }}
                    label={
                      <Typography sx={{ fontSize: ".8rem" }}>
                        {active === "PendingDoNotShow"
                          ? "Pending"
                          : active === ""
                          ? "Pending"
                          : _.startCase(active)}
                      </Typography>
                    }
                  />
                )}
              </Stack>
            </>
          )}
        </ImageBox>
        <CardContent sx={{ padding: "1rem 1.5rem" }}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack direction={"row"} gap={".5rem"} alignItems={"center"}>
              <Typography
                component={"p"}
                variant="h6"
                sx={{ fontWeight: 600, fontSize: "1.2rem" }}
              >
                ${price}
              </Typography>
              {(active === "Sold" || active === "Closed") && (
                <Typography
                  variant="body2"
                  sx={{ fontSize: ".9rem" }}
                  color={"GrayText"}
                >
                  Sold Price
                </Typography>
              )}
            </Stack>
            {active === "Sold" || active === "Closed" ? null : (
              <IconButton disableTouchRipple onClick={addToFav}>
                {load ? (
                  <CircularProgress size="1.33rem" color="secondary" />
                ) : isFav ? (
                  <Favorite color="secondary" fontSize="small" />
                ) : (
                  <FavoriteBorder color="secondary" fontSize="small" />
                )}
              </IconButton>
            )}
          </Box>
          <Box display={"flex"} gap={".8rem"}>
            <Box sx={{ display: "flex", gap: ".2rem" }}>
              <Tooltip title="Bedroom" arrow>
                <KingBed sx={{ fontSize: "1.4rem" }} color="secondary" />
              </Tooltip>
              <Typography fontWeight={400} variant="body2" color={"gray"}>
                {bed}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: ".2rem" }}>
              <Tooltip title="Bathroom" arrow>
                <Shower sx={{ fontSize: "1.4rem" }} color="secondary" />
              </Tooltip>
              <Typography variant="body2" fontWeight={400} color={"gray"}>
                {bath}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "0rem" }}>
              <Tooltip title="Square Ft" arrow>
                <SquareFoot sx={{ fontSize: "1.4rem" }} color="secondary" />
              </Tooltip>
              <Typography fontWeight={400} variant="body2" color={"gray"}>
                {area} Sqft
              </Typography>
            </Box>
          </Box>
          <Typography
            component={"h3"}
            mt={1}
            sx={{ fontSize: ".9rem", fontWeight: 400, color: "gray" }}
            noWrap
            color={"textPrimary"}
          >
            {address}
          </Typography>
          {(active === "Sold" || active === "Closed") && (
            <Typography
              component={"p"}
              mt={0.5}
              sx={{ fontSize: ".8rem" }}
              noWrap
              color={"textSecondary"}
            >
              Listing by {office?.name}
            </Typography>
          )}
          {active === "Sold" || active === "Closed" ? null : (
            <>
              <Divider sx={{ my: 1 }} light />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Tooltip
                  sx={{ bgcolor: "white" }}
                  arrow
                  title={
                    <>
                      <Typography
                        fontWeight={400}
                        variant="body2"
                        fontSize={".8rem"}
                      >
                        Want to <strong>Fix,Flip,Rent or Develop</strong>? Use
                        our <strong>Deal Analyzer</strong> for FREE!{" "}
                      </Typography>
                    </>
                  }
                >
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      window.location.assign(
                        `${process.env.REACT_APP_BPOHOMESTECH_REDIRECT}analyze-deal/?address=${address}`
                      );
                    }}
                    size="small"
                    sx={{
                      textTransform: "none",
                      fontWeight: 500,
                      fontSize: ".7rem",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    disableElevation
                    disableRipple
                    variant="outlined"
                    color="secondary"
                  >
                    Analyze This Property
                  </Button>
                </Tooltip>

                <Tooltip
                  arrow
                  title={
                    <>
                      <Typography
                        fontWeight={500}
                        variant="body2"
                        fontSize={".7rem"}
                      >
                        {" "}
                        DON’T TRUST AI ESTIMATES.Before you Buy get a{" "}
                        <strong strong> Broker Price Opinion</strong> from a{" "}
                        <strong> Local Agent TODAY!</strong>
                      </Typography>
                    </>
                  }
                >
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      window.location.assign(
                        `${process.env.REACT_APP_BPOHOMESTECH_REDIRECT}order-bpo-report`
                      );
                    }}
                    size="small"
                    sx={{
                      overflow: "hidden",
                      textTransform: "none",
                      fontWeight: 400,
                      fontSize: ".8rem",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    disableElevation
                    disableRipple
                    variant="outlined"
                    color="secondary"
                  >
                    Request Agent Insight
                  </Button>
                </Tooltip>
              </Box>
            </>
          )}
        </CardContent>
      </StyledCard>
    </>
  );
};

const mapStateToprops = (state) => {
  return state;
};

export default connect(mapStateToprops, { addToFavorite, removeFavorite })(
  ListingCard
);

//

//
